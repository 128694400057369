import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import * as PropTypes from 'prop-types'

import { fbTrack } from 'src/utils/tracking'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import MoreFromThePost from 'src/components/MoreFromThePost'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import { typography, util } from 'src/styles'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const Text = styled.div`
  text-align: center;
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const ArticleTitle = styled.h1`
  margin-bottom: .5em;
  text-align: center;
  ${ util.responsiveStyles('margin-top', 20, 20, 30, 40) }
`

class Article extends React.Component {

  componentDidMount () {
    const { pageContext } = this.props
    const contentId = pageContext.id
    fbTrack('track', 'ViewContent', {
      content_type: 'article',
      content_ids: contentId
    })
  }

  render () {
    const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
    const article = this.props.data.allContentfulArticle.edges[0].node
    const { sections } = article
    const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
    const seo = article.seo
    return (
      <Fragment >
        <SEO
          title={article.title}
          description={seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo.keywords}
          shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
        />
        <Header
          hasAtf={hasAtf}
          bannerText={site && site.bannerText}
          bannerColor={site && site.bannerColor}
          navigation={site && site.navigation}
        />

        <Drawer />
        <ResponsiveComponent
          small={
            <MobileMenu
              navLinks={site && site.navigation}
            />
          }
          large={<span />}
        />
        <div>
          <Grid
            small="1 [12] 1"
            medium="2 [12] 2"
            large="3 [8] 3"
            larger="4 [6] 4"
          >
            <ArticleTitle>{article.title}</ArticleTitle>
            {article.previewText && <Text textSize='body'><ContentfulRichText richText={article.previewText.json} /></Text>}
          </Grid>
        </div>
        <div style={{ paddingTop: '60px', paddingBottom: '60px' }}>
          <Grid
            small="1 [12] 1"
            medium="2 [12] 2"
            large="3 [8] 3"
            larger="4 [6] 4"
          >
            <Image
              {...article.previewImage}
            />
          </Grid>
        </div>
        <Grid
          small="[14]"
          medium="1 [14] 1"
          large="4 [16] 4"
          larger="8 [16] 8"
        >
        {sections && sections.map((section, index) => {
          const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
          const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
          const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
          const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
          const lastSection = sections.length === index + 1

          return (
            <ComponentRenderer
              prevTheme={prevFullWidth ? false : prevTheme}
              nextTheme={nextFullWidth ? false : nextTheme}
              isFirstSection={index === 0}
              isLastSection={lastSection}
              key={`${ section.id }_${ index }`}
              item={section}
              index={index}
            />
          )
        })}
        </Grid>
        <MoreFromThePost articleId={article.id} />
        <Footer {...site} footerTheme='green' />
      </Fragment>
    )
  }
}

Article.propTypes = propTypes

export const articleQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulArticle(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
          previewImage {
            ...Image
          }
          previewText {
            json
          }
					sections {
						...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default Article
